<template>
  <div>
    <div class="res-header-container">
      <a   @click="openDrop()">
        <img src="@/assets/images/menu-up.svg" alt="" />
      </a>
      <img
        class="logo-for-res"
        :src="$store.state.company_profile.logo"
        alt="Logo"
      />
      <!-- <div class="onoffswitch for-responsive">
        <input
          type="checkbox"
          name="onoffswitch"
          class="onoffswitch-checkbox"
          id="swichBahasa"
          tabindex="0"
        />
        <label class="onoffswitch-label" for="swichBahasa">
          <span class="onoffswitch-inner"></span>
          <span class="onoffswitch-switch"></span>
        </label>
      </div> -->
    </div>
    <div class="for-res-down" v-bind:class="{active : IsForDropdown == true}">
      <div class="for-logo-closed">
        <img
          class="logo-for-res"
          :src="$store.state.company_profile.logo"
          alt="Logo"
        />
        <a @click.prevent="closedDrop()">
          <i class="fa fa-close"></i>
        </a>
      </div>
      <div class="menu-item-res menu-mobile">
        <ul>
          <li  v-bind:class="{ active: isOpen == 'beranda' }">
            <router-link to="/"  @click="closedDrop()">
              <span @click="closedDrop()">  Beranda </span>
            </router-link>
          </li>
          <template v-for="(m, i) in menu">
            <li  v-bind:class="{ active: isOpen == m.id }" :key="i">
              <template v-if="m.childs.length > 0">
                <a href="#" @click.prevent="toggleAccordion(m.id)"> {{ m.name}} 
                  <i class="fa fa-angle-up float-right"></i>
                  <ul  v-bind:class="{ active: isOpen == m.id }">
                    <li v-for="(chil, y) in m.childs" :key="y">
                      <template v-if="chil.name.toLowerCase() == 'pejabat'">
                        <router-link to="/pejabat">
                          <span @click="closedDrop()">  Pejabat </span>
                        </router-link>
                      </template>
                      <template v-else>
                        <template v-if="chil.childs.length > 0">
                          <a href="#" @click.prevent="isOpenChild = chil.id">
                            {{ chil.name}} 
                            <i class="fa fa-angle-up float-right"></i>
                            <ul  v-bind:class="{ active: isOpenChild == chil.id }">
                              <li v-for="(childs, z) in chil.childs" :key="z">
                                <template v-if="childs.cta == 'page'">
                                  <a href="#" @click.prevent="redirectToPage(childs.page)"> {{ childs.name}} </a>
                                </template>
                                <template v-else>
                                  <a href="#" @click.prevent="redirectToPost(childs.post)"> {{ childs.name}} </a>
                                </template>
                              </li>
                            </ul>
                          </a>
                        </template>
                        <template v-else>
                          <template v-if="chil.cta == 'page'">
                            <a href="#" @click.prevent="redirectToPage(chil.page)"> {{ chil.name}} </a>
                          </template>
                          <template v-else>
                            <a href="#" @click.prevent="redirectToPost(chil.post)"> {{ chil.name}} </a>
                          </template>
                        </template>
                      </template>
                    </li>
                  </ul>
                </a>
              </template>
              <template v-else>
                <template v-if="m.cta == 'page'">
                  <a href="#" @click.prevent="redirectToPage(m.page)"> {{ m.name}} </a>
                </template>
                <template v-else>
                  <a href="#" @click.prevent="redirectToPost(m.post)"> {{ m.name}} </a>
                </template>
              </template>
          </li>
          </template>
          <li v-bind:class="{ active: isOpen == 'infopublik' }">
            <a href="#"  @click.prevent="toggleAccordion('infopublik')">Informasi
              <i class="fa fa-angle-up float-right"></i>
                <ul v-bind:class="{ active: isOpen == 'infopublik' }">
                  <li>
                    <router-link to="/public-information"  >
                      <span @click="closedDrop()">  Daftar Informasi Publik </span>
                    </router-link> 
                  </li>
                  <li>
                    <router-link to="/important-information"  >
                      <span @click="closedDrop()">  Daftar Informasi Penting</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/regulation-information"  >
                      <span @click="closedDrop()">  Daftar Regulasi</span>
                    </router-link>
                  </li>
                  <li v-for="(m, i) in menuInfoPublic.childs" :key="i">
                    <template v-if="m.id != 101">
                      <template v-if="m.cta == 'page'">
                        <a href="#" @click="redirectToPage(m.page)">
                          <span>{{ m.name }}</span>
                        </a>
                      </template>
                      <template v-else>
                        <a href="#" @click="redirectToPost(m.post)">
                          <span>{{ m.name }}</span>
                        </a>
                      </template>  
                    </template>  
                  </li>
                </ul>
            </a>
          </li>
          <li v-bind:class="{ active: isOpen == 'publikasi' }">
            <a href="#"  @click.prevent="toggleAccordion('publikasi')">Publikasi
              <i class="fa fa-angle-up float-right"></i>
                <ul   v-bind:class="{ active: isOpen == 'publikasi' }">
                  <li>
                    <router-link to="/gallery">
                      <span @click="closedDrop()">  Galeri </span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/berita">
                      <span @click="closedDrop()">  Berita </span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/article"> 
                      <span @click="closedDrop()"> Artikel dan Opini </span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/announcement"> 
                      <span   @click="closedDrop()">Pengumuman</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/calendar"> 
                      <span   @click="closedDrop()">Kalender</span>
                    </router-link>
                  </li>
                </ul>
            </a> 
          </li>
          <li v-bind:class="{ active: isOpen == 'layanan' }">   
            <router-link to="/layanan"  @click="closedDrop()">
              <span @click="closedDrop()">  Layanan </span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
   data() {
    return {
      isOpen:'beranda',
      isOpenForSub:'',
      IsForDropdown: false,
      menu: [],
      menuInfoPublic: [],
      menuRegulasi: [],
      forSubMegaRegulasi: [],
      isOpenChild:''
    }
   },
   mounted() {
     this.getMenu(),
    this.getMenuRegulasi()
   },
   methods: {
     getMenu() {
      this.axios.get('menu/list/header')
      .then( response => {
        this.menu = response.data.result.menus.filter( menu => menu.id != 100)
        this.menuInfoPublic = response.data.result.menus.find( menu => menu.id == 100)
      })
    },
    getMenuRegulasi() {
      this.axios.get('menu/101/list-child')
      .then( response => {
        this.menuRegulasi = response.data.result.menus
      })
    },
    redirectToPost(info) {
      this.closedDrop()
      if (info.type == 'redirect') {
        window.open(info.link, '_blank');
      } else if (info.type == 'download') {
        window.open(info.link, '_blank');
      } else {
        this.$router.push(`/post/${info.slug}`)
      }
    },
    redirectToPage(page) {
      this.closedDrop()
      if (page) {
        this.$router.push(`/page/${page.slug}`)
      } else {
        this.$router.push('/404')
      }
    },
     closedDrop(){
       this.IsForDropdown = false
     },
     openDrop(){
        this.IsForDropdown = true
 
     },
     toggleAccordion(val){
       //this.isOpen = ''
       this.isOpen = val
        // if(val == 'profil'){
        //   this.isOpen = val
        // }
        //  if(val == 'publikasi'){
        //   this.isOpen = val
        // }
        //  if(val == 'infopublik'){
        //   this.isOpen = val
        // }
        //   if(val == 'layanan'){
        //   this.isOpen = val
        // }
        //  if(val == 'beranda'){
        //   this.isOpen = val
        // }
     }
   },
  
}
</script>
 
<template>
  <div class="container-fluid set-bg-foot pt-39 pb-39">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <img
            class="mb-20"
            src="@/assets/images/compro/logo/logo.png"
            alt=""
          />
          <span class="d-block font-14 ln-2 color-grey-dark mb-20">
            {{ $store.state.company_profile.copyright }}
          </span>
          <template v-if="$store.state.company_profile.youtube && $store.state.company_profile.youtube_link">
            <div class="items-foot mb-18">
              <img src="@/assets/images/compro/icon/ytobe.svg" alt="" />
              <a target="_blank" :href="$store.state.company_profile.youtube_link">
                <span class="ml-2 font-14 color-grey-dark">{{ $store.state.company_profile.youtube }}</span>
              </a>
            </div>
          </template>
          <template v-if="$store.state.company_profile.twitter && $store.state.company_profile.twitter_link">
            <div class="items-foot mb-18">
              <img src="@/assets/images/compro/icon/tw.svg" alt="" />
              <a target="_blank" :href="$store.state.company_profile.twitter_link">
                <span class="ml-2 font-14 color-grey-dark">{{ $store.state.company_profile.twitter }}</span>
              </a>
            </div>
          </template>
          <template v-if="$store.state.company_profile.instagram && $store.state.company_profile.instagram_link">
            <div class="items-foot mb-18">
              <img src="@/assets/images/compro/icon/insta.svg" alt="" />
              <a target="_blank" :href="$store.state.company_profile.instagram_link">
                <span class="ml-2 font-14 color-grey-dark">{{ $store.state.company_profile.instagram }}</span>
              </a>
            </div>
          </template>
          <template v-if="$store.state.company_profile.facebook && $store.state.company_profile.facebook_link">
            <div class="items-foot mb-18">
              <img src="@/assets/images/compro/icon/fb.svg" alt="" />
              <a target="_blank" :href="$store.state.company_profile.facebook_link">
                <span class="ml-2 font-14 color-grey-dark">{{ $store.state.company_profile.facebook }} </span>
              </a>
            </div>
          </template>
        </div>
        <div class="col-md-3">
          <span class="d-block font-14 font-weight-6 color-grey-dark pt-3 mb-19">Tentang BPJPH</span>
          <template v-for="(m, i) in menu">
            <span class="d-block font-14 color-grey-dark pt-2 cursor-pointer trasition-bounce" :key="i">
              <template v-if="m.cta == 'page'">
                <router-link :to="`/page/${m.page.slug}`">
                  {{ m.name }}
                </router-link>
              </template>
              <template v-else>
                <span @click="redirectToPost(m.post)">{{ m.name }}</span>
              </template>  
            </span>
          </template>
          <span class="d-block font-14 mb-10 color-grey-dark pt-2 cursor-pointer trasition-bounce">
            <router-link to="/pejabat">Pejabat</router-link>  
          </span>
          <div class="items-foot mb-15 pt-3">
            <img src="@/assets/images/compro/icon/on.svg" alt="" />
            <span class="ml-2 font-14 color-grey-dark">{{ visitor.online }} &nbsp; &nbsp; Pengunjung online</span>
          </div>
          <div class="items-foot mb-15">
            <img src="@/assets/images/compro/icon/on.svg" alt="" />
            <span class="ml-2 font-14 color-grey-dark">{{ visitor.today}} &nbsp; &nbsp; Pengunjung hari ini</span>
          </div>
          <div class="items-foot mb-15">
            <img src="@/assets/images/compro/icon/off.svg" alt="" />
            <span class="ml-2 font-14 color-grey-dark">{{ visitor.thisYear }} &nbsp; &nbsp; Pengunjung tahun berjalan</span>
          </div>
          <div class="items-foot mb-15">
            <img src="@/assets/images/compro/icon/off.svg" alt="" />
            <span class="ml-2 font-14 color-grey-dark">{{ visitor.allYear }} &nbsp; &nbsp; Pengunjung sejak website berdiri</span>
          </div>
        </div>
        <div class="col-md-3">
          <span class="d-block font-14 font-weight-6 color-grey-dark pt-3 mb-19"
            >Kontak</span
          >
          <div class="items-foot-te mb-15">
            <img
              class="mt-1"
              src="@/assets/images/compro/icon/loc.svg"
              alt=""
            />
            <span class="ml-3 font-14 color-grey-dark">
              {{ $store.state.company_profile.address }}
            </span>
          </div>
          <div class="items-foot-te mb-15">
            <img
              class="mt-1"
              src="@/assets/images/compro/icon/phone.svg"
              alt=""
            />
            <span class="ml-3 font-14 color-grey-dark">{{ $store.state.company_profile.phone }}</span>
          </div>
        </div>
          <div class="col-md-3">
            <span class="d-block font-14 font-weight-6 color-grey-dark pt-3 mb-19">Lokasi</span >
            <div class="for-iframe">
              <gmap-map :center="{ lat: $store.state.company_profile.latitude, lng: $store.state.company_profile.longitude }" 
                :zoom="15" class="for-map">
                <gmap-marker
                  :position="{ lat: $store.state.company_profile.latitude, lng: $store.state.company_profile.longitude }"
                  :clickable="true"
                ></gmap-marker>
                </gmap-map>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer',
  data() {
    return {
      menu: [],
      visitor: {
        today: 0,
        thisYear: 0,
        allYear: 0,
        online: 0
      }
    }
  },
  mounted() {
    this.getMenu()
    this.getVisitor()
  },
  methods: {
    getVisitor() {
      this.axios.get('visitor')
      .then( response => {
        this.visitor = response.data.result
      })
    },
    getMenu() {
      this.axios.get('menu/list/footer')
      .then( response => {
        this.menu = response.data.result.menus
      })
    },
    redirectToPost(info) {
      if (info.type == 'redirect') {
        window.open(info.link, '_blank');
      } else if (info.type == 'download') {
        window.open(info.link, '_blank');
      } else {
        this.$router.push(`/post/${info.slug}`)
      }
    },
  }
}
</script>
<template>
  <div id="app"> 
      <headerVue></headerVue> 
      <!-- <breadcumb v-show="$store.state.bgSetting"></breadcumb> -->
     <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOutUp">
     <router-view>
      </router-view>
    </transition>
    <footerVue></footerVue>
  </div>
</template>

 <script>
 import headerVue from '@/components/header.vue'
  import footerVue from '@/components/footer.vue'
  // import breadcumb from '@/components/breadcumb.vue'
 export default {
   components:{
     headerVue,
     footerVue,
    //  breadcumb
   }
   
 }
 </script>